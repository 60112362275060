// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware(() => {
  const { $localePath } = useNuxtApp();
  const $auth = useAuth();

  if ($auth.loggedIn && $auth.user.role !== 'admin') {
    return navigateTo($localePath({
      name: 't-tenantSlug-m',
      params: { tenantSlug: $auth.user.workspace.slug },
    }));
  }
});
